<template>
  <b-card id="page--forms" no-body>
    <div>
      <div class="m-2 d-flex justify-content-between table-actions">
        <div class="d-flex">
          <div class="mr-1">
            <b-button
              id="create-button"
              variant="primary"
              :class="{
                'icon-disabled': !canCreate,
              }"
              @click="canCreate && create()"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">{{ $t('Create New') }}</span>
            </b-button>
            <b-tooltip target="create-button">
              {{
                canCreate ? $t('Create') : $t("You do not have permission to create Document Template")
              }}
            </b-tooltip>
          </div>
        </div>
      </div>
      <div class="m-2">
        <b-table
          ref="refTable"
          class="table-responsive"
          :busy="loading"
          :items="items"
          :fields="tableColumns"
          responsive
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(name)="{ item }">
            <prozess-resource-name
              :title="item.name"
                :to="{
                name: 'document-template.view',
                params: {
                  id: item.id,
                }}"
                />
          </template>

          <!-- START: Actions Column -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap" style="opacity: 0.5; cursor: default;">
              <feather-icon
                :id="`edit-row-${item.id}-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1 cursor-pointer"
              />
              <!-- disable edit for now -->
              <!-- @click="$router.push({ name: 'document-template.view', params: { id: item.id },})" -->
              <!-- /> -->
              <b-tooltip :target="`edit-row-${item.id}-icon`" placement="left">
                {{$t('Edit') }}
              </b-tooltip>
              <feather-icon
                :id="`delete-row-${item.id}-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="confirmRemoval(item)"
              />
              <b-tooltip placement="left" :target="`delete-row-${item.id}-icon`">
              {{$t('Delete') }}
              </b-tooltip>
            </div>
          </template>
          <!-- END: Actions Column -->
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import { swalConfirmDeleteOption } from '@/helpers/app'
import contractTemplateService from '@/services/contractTemplate'
import listMixinFactory from '@/mixins/list'

export default {
  mixins: [
    listMixinFactory({
      routeName: 'document-template',
      searchField: 'name',
      service: contractTemplateService,

      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'actions', class: 'text-center disabled' },
      ],

      sort: {
        name: 'name',
      },
    }),
  ],

  computed: {
    canCreate() {
      return true
      // return this.$can('Create', 'Contract_Template')
    },
  },

  methods: {
    viewTemplate(item) {
      console.log('item clicked', item)
    },
    confirmRemoval(item) {
        this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(
          async result => {
            if (result.value) {
              await this.$async(
                contractTemplateService.deleteById(item.id),
              )

              this.fetchList()
            }
          },
        )
    },

    create() {
      this.$router.push({ name: 'document-template.create' })
    },
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"id":"page--forms","no-body":""}},[_c('div',[_c('div',{staticClass:"m-2 d-flex justify-content-between table-actions"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1"},[_c('b-button',{class:{
              'icon-disabled': !_vm.canCreate,
            },attrs:{"id":"create-button","variant":"primary"},on:{"click":function($event){_vm.canCreate && _vm.create()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Create New')))])],1),_c('b-tooltip',{attrs:{"target":"create-button"}},[_vm._v(" "+_vm._s(_vm.canCreate ? _vm.$t('Create') : _vm.$t("You do not have permission to create Document Template"))+" ")])],1)])]),_c('div',{staticClass:"m-2"},[_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"busy":_vm.loading,"items":_vm.items,"fields":_vm.tableColumns,"responsive":"","primary-key":"id","show-empty":"","empty-text":_vm.$t('No matching records found'),"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"sort-changed":_vm.handleSortChange},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head()",fn:function({ label }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(name)",fn:function({ item }){return [_c('prozess-resource-name',{attrs:{"title":item.name,"to":{
              name: 'document-template.view',
              params: {
                id: item.id,
              }}}})]}},{key:"cell(actions)",fn:function({ item }){return [_c('div',{staticClass:"text-nowrap",staticStyle:{"opacity":"0.5","cursor":"default"}},[_c('feather-icon',{staticClass:"mx-1 cursor-pointer",attrs:{"id":`edit-row-${item.id}-icon`,"icon":"EditIcon","size":"16"}}),_c('b-tooltip',{attrs:{"target":`edit-row-${item.id}-icon`,"placement":"left"}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",attrs:{"id":`delete-row-${item.id}-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.confirmRemoval(item)}}}),_c('b-tooltip',{attrs:{"placement":"left","target":`delete-row-${item.id}-icon`}},[_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")])],1)]}}])})],1),(!_vm.loading)?_c('prozess-pagination',{staticClass:"mx-2 mb-2",class:{ 'd-none': !_vm.total },attrs:{"meta":_vm.dataMeta,"page":_vm.currentPage,"total":_vm.total,"size":_vm.perPage},on:{"change":_vm.handleCurrentPage}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }